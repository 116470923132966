<template>
    <div class="font-inter" data-app="true">
        <Toast
            :toast_show_error="toast_show_error"
            :toast_show_success="toast_show_success"
            :toast_payment_status="toast_payment_status"
            :toast_payment_data_status="toast_payment_data_status"
            :toast_error="toast_error"
            :toast_message="toast_message"
        />
        <Widget
            v-if="$widget"
            :widget="$widget"
            :form="form"
            :order_created="order_created"
            :order_status_message="order_status_message"
            :is_login="auth_after_send_order"
            @submit="submit"
            @show-error-toast="show_error_toast"
            @success-forgot-password="onSuccessForgotPassword"
            @error-login="onErrorLogin"
            ref="Widget"
        />
        <v-overlay v-show="preloader">
            <v-progress-circular indeterminate.prop="disable-shrink"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import Widget from "../components/widget/Widget.vue";
import Toast from "../components/widget/Toast.vue";
import {submitOrder} from "./api";

export default {
    components: {Widget, Toast},
    data: function () {
        return {
            form: {
                name: "",
                email: "",
                phone: "",
                details: "",
                date: "",
                date_object: "",
                schedule_id: "",
                schedule_object: "",
                delivery: "",
                errors: {},
                delivery_enabled: false,
                create_customer: false,
                is_vending: false,
            },
            blocked_btn: false,
            toast_show_error: false,
            toast_show_success: false,
            toast_message: '',
            toast_payment_status: false,
            toast_payment_data_status: false,
            toast_error: [],
            order_created: false,
            order_status_message: '',
            auth_after_send_order: false,
            preloader: false,
        };
    },
    methods: {
        async submit(cart) {
            if (this.has_errors()) {
                return 0
            }
            this.auth_after_send_order = false
            this.$refs.Widget.setBlocked_btn()
            this.order_status_message = 'Er wordt een bestelling aangemaakt'
            this.form.errors = {};
            this.form.cart = JSON.stringify(cart)
            const response = await submitOrder(this.form);
            if (response.success) {
                if (response.token) {
                    localStorage.widgetAuthTokenCustomer = response.token
                    localStorage.customer = response.customer
                    this.auth_after_send_order = true
                }
                this.show_success_toast()
                this.order_created = true
                this.toast_error.push(response.error_message)
                if (cart.length > 0) {
                    this.preloader = true;
                    if (this.form.payment_method === 'mollie') {
                        this.payment_mollie(cart, response.order.id);
                    } else {
                        window.location.reload();
                    }
                }
                setTimeout(() => this.order_status_message = '', 5000)
                this.order_status_message = 'Bedankt voor uw order! Wij zorgen voor de goede verwerking.'
                // success
                this.$refs.Widget.setOpen_btn()
            } else {
                this.order_created = false
                this.show_error_toast(this.get_first_error(response.errors, response.message))
                setTimeout(() => this.order_status_message = '', 5000)
                this.order_status_message = 'Fout bij maken van bestelling! Probeer het opnieuw of bel ons.'
                // error
                this.$refs.Widget.blocked_btn = false;
            }
        },
        get_first_error(errors, default_message = '') {
            return Object.values(errors ?? []).flat().at(0) ?? default_message
        },
        has_errors() {
            if (Object.keys(this.form.errors).length > 0) {
                this.show_error_toast(this.get_first_error(this.form.errors))
                return true
            } else if ((Object.values(this.form.schedule_object).length === 0) || this.form.date_object.is_holiday) {
                this.show_error_toast(this.$t('general.select-shipping'))
                return true
            }
            return false
        },
        show_success_toast() {
            this.toast_show_success = true
            setTimeout(() => {
                this.toast_show_success = false
                this.order_status_message = ''
            }, 5000)
            this.order_status_message = 'De bestelling is aangemaakt. Bedankt voor je bestelling!'
        },
        show_error_toast(error) {
            this.toast_show_error = true
            this.toast_error.push(error)
            setTimeout(() => {
                this.toast_show_error = false
                this.order_status_message = ''
                this.toast_error = []
            }, 5000)
            this.order_status_message = error
        },
        show_payment_toast() {
            this.toast_payment_status = true
            setTimeout(() => {
                this.toast_payment_status = false
                this.order_status_message = ''
            }, 5000)
            this.order_status_message = 'Bestelling is betaald. Bedankt voor je bestelling!'
        },
        payment_mollie(cart, order_id) {
            if (document.getElementById("script-widget-base-url")) {
                const tag_script = document.getElementById("script-widget-base-url");
                this.baseUrl = tag_script.dataset.widgetBaseUrl
                this.token = tag_script.dataset.token;
                cart = JSON.stringify(cart)
                let url = window.location.href
                //window.location.href = this.baseUrl + `/mollie-payment/?token=${this.token}&cart=${cart}&url=${url}&order_id=${order_id}`;
                window.location.href = this.baseUrl + `/mollie-payment/?token=${this.token}&url=${url}&order_id=${order_id}`;
            }
        },
        init() {
            let params = (new URL(document.location)).searchParams;
            let order_id = params.get("order_id");
            if (order_id) {
                let result = this.getStatusPayment(order_id)
                result.then((response) => {
                    this.show_payment_toast()
                    this.toast_payment_data_status = response.status.status
                    const url = new URL(document.location);
                    const searchParams = url.searchParams;
                    searchParams.delete("order_id");
                    window.history.pushState({}, '', url.toString());
                })
            }
        },
        async getStatusPayment(order_id) {
            if (document.getElementById("script-widget-base-url")) {
                const tag_script = document.getElementById("script-widget-base-url");
                let baseUrl = tag_script.dataset.widgetBaseUrl
                let token = tag_script.dataset.token

                const response = await fetch(`${baseUrl}/api/public/mollie-status/${order_id}/${token}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        token: token,
                        mode: "cors",
                        order_id: order_id
                    }
                });
                return await response.json();
            }
        },
        onSuccessForgotPassword() {
            this.toast_show_success = true
            this.toast_message = this.$t('general.password-reset-confirmed')
            setTimeout(() => {
                this.toast_show_success = false
                this.toast_message = ''
            }, 5000)
        },
        onErrorLogin() {
            this.toast_show_error = true;
            this.toast_message = this.$t('general.error-login');
            setTimeout(() => {
                this.toast_show_error = false;
                this.toast_message = '';
            }, 5000);
        }
    },
    mounted() {
        this.init()
    }
};
</script>
<style scoped>
.font-inter {
    font-family: "Inter", sans-serif;
}
</style>
