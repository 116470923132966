import Vue from "vue";
import App from "@/widget/App";
import i18n from "../i18n";
import { getWidget } from "./api.js";

require("./app.css");

const init = async function () {
    const widget = await getWidget();
    const containerId = "#widget-container";

    if (!document.querySelector(containerId)) {
        console.error("Container not found!");
    }

    Vue.prototype.$widget = widget;

    try {
        new Vue({
            i18n,
            render: h => h(App)
        }).$mount(containerId);
    } catch (e) {
        console.error(e);
        console.log("widget is not mounted");
    }
};

document.addEventListener(
    "DOMContentLoaded",
    function () {
        init().then(() => {
            console.log("widget is loaded");
        });
    },
    false
);


