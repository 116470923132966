<template>
    <transition name="slide-fade">
        <div class="toast-error" v-if="toast_show_error">
                <span aria-hidden="true" class="v-icon notranslate v-alert__icon theme--dark"><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true"
                    class="v-icon__svg"><path
                    d="M8.27,3L3,8.27V15.73L8.27,21H15.73C17.5,19.24 21,15.73 21,15.73V8.27L15.73,3M9.1,5H14.9L19,9.1V14.9L14.9,19H9.1L5,14.9V9.1M11,15H13V17H11V15M11,7H13V13H11V7"></path></svg></span>
            <span class="toast-error__msg">{{ toast_message ? toast_message : $t('general.order-not-placed') }}</span>
            <ul class="toast-error__list">
                <li v-for="error in toast_error" :key="error" class="toast-error__item">
                    {{ error }}
                </li>
            </ul>
        </div>
        <div class="toast-success" v-if="toast_show_success">
            <span aria-hidden="true" class="v-icon notranslate v-alert__icon theme--dark"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true"
                class="v-icon__svg"><path
                d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"></path></svg></span>
            <span class="toast-success__msg">{{ (toast_message) ? toast_message : $t('general.order-successfully-created') }}</span>
        </div>

        <div class="toast-payment-status" v-if="toast_payment_status">
            <span aria-hidden="true" class="v-icon notranslate v-alert__icon theme--dark"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true"
                class="v-icon__svg"><path
                d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"></path></svg></span>
            <span class="toast-success__msg">{{ $t('general.payment-status') }}: {{ toast_payment_data_status }}</span>
        </div>
    </transition>
</template>

<script>

export default {
    props: [
        "toast_show_error",
        "toast_show_success",
        "toast_error",
        "toast_payment_status",
        "toast_payment_data_status",
        "toast_message",
    ],
};
</script>

<style lang="scss" scoped>

.toast-error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 270px;
    padding: 20px;
    position: fixed;
    top: 100px;
    right: 5px;
    border: 1px solid #B71C1C;
    border-radius: 5px;
    background: #ff5252;
    z-index: 100;

    &__list {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        text-align: center;

        .toast-error__item {
            font-size: 16px;
            color: #fff;
            list-style: none;
            display: block;
        }
    }

    &__msg {
        font-size: 16px;
        color: #fff;
        display: block;
    }
}

.toast-success {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    padding: 20px;
    position: fixed;
    top: 10px;
    right: 5px;
    border: 1px solid #1B5E20;
    border-radius: 5px;
    background: #4caf50;
    z-index: 100;

    &__msg {
        font-size: 16px;
        color: #fff;
    }
}

.toast-payment-status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    padding: 20px;
    position: fixed;
    top: 10px;
    right: 5px;
    border: 1px solid #1b395e;
    border-radius: 5px;
    background: #4c92ad;
    z-index: 100;

    &__msg {
        font-size: 16px;
        color: #fff;
    }
}

.v-icon.v-icon {
    align-items: center;
    display: inline-flex;
    font-feature-settings: "liga";
    font-size: 24px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    text-indent: 0;
    transition: .3s cubic-bezier(.25, .8, .5, 1), visibility 0s;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
    margin-right: 10px;
}

.v-icon__svg {
    height: 24px;
    width: 24px;
    fill: currentColor;
    overflow-clip-margin: content-box;
    overflow: hidden;
}

/* Animation */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.5s;
}

.slide-fade-enter {
    transform: translateX(275px);
    opacity: 0
}
.slide-fade-enter-to {
    transform: translateX(0);
    opacity: 1;
}

.slide-fade-leave {
    transform: translateX(0);
    opacity: 1;
}

.slide-fade-leave-to {
    transform: translateX(275px);
    opacity: 0;
}
</style>
